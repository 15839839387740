// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biblioteca-index-js": () => import("./../src/pages/biblioteca/index.js" /* webpackChunkName: "component---src-pages-biblioteca-index-js" */),
  "component---src-pages-calendario-index-js": () => import("./../src/pages/calendario/index.js" /* webpackChunkName: "component---src-pages-calendario-index-js" */),
  "component---src-pages-capacitacion-empresas-index-js": () => import("./../src/pages/capacitacion/empresas/index.js" /* webpackChunkName: "component---src-pages-capacitacion-empresas-index-js" */),
  "component---src-pages-capacitacion-sitimm-index-js": () => import("./../src/pages/capacitacion/sitimm/index.js" /* webpackChunkName: "component---src-pages-capacitacion-sitimm-index-js" */),
  "component---src-pages-catalogo-index-js": () => import("./../src/pages/Catalogo/index.js" /* webpackChunkName: "component---src-pages-catalogo-index-js" */),
  "component---src-pages-conocenos-index-js": () => import("./../src/pages/Conocenos/index.js" /* webpackChunkName: "component---src-pages-conocenos-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-convenios-index-js": () => import("./../src/pages/convenios/index.js" /* webpackChunkName: "component---src-pages-convenios-index-js" */),
  "component---src-pages-educacion-index-js": () => import("./../src/pages/educacion/index.js" /* webpackChunkName: "component---src-pages-educacion-index-js" */),
  "component---src-pages-eventos-index-js": () => import("./../src/pages/eventos/index.js" /* webpackChunkName: "component---src-pages-eventos-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nexos-index-js": () => import("./../src/pages/Nexos/index.js" /* webpackChunkName: "component---src-pages-nexos-index-js" */),
  "component---src-pages-oficinas-index-js": () => import("./../src/pages/oficinas/index.js" /* webpackChunkName: "component---src-pages-oficinas-index-js" */),
  "component---src-pages-revistas-index-js": () => import("./../src/pages/revistas/index.js" /* webpackChunkName: "component---src-pages-revistas-index-js" */),
  "component---src-pages-revistas-magazine-template-js": () => import("./../src/pages/revistas/magazineTemplate.js" /* webpackChunkName: "component---src-pages-revistas-magazine-template-js" */),
  "component---src-pages-servicios-index-js": () => import("./../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

